<template>
    <div>
        <div class="header" id="header">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组763_1686894590711.png" class="hwlogo" @click="toindex">
            <div class="headericon" id="headericon" @click="headericon"></div>
        </div>
        <div class="headerhidden" id="headerhidden">
            <div class="headeritem" @click="toindex"> <span> 首页 </span> </div>
            <div class="headeritem" v-for="(item) in columnData" :key="item.id" @click="toLink(item.url)">
                <span>{{item.columnName}}</span>
            </div>
        </div>
        <div style="height: 1.08rem;"></div>

        <div class="newsbox">
            <div class="newstitle"  v-if="articlefrom">{{articlefrom.fullTitle}}</div>
            <div class="newstitle2"  v-if="articlefrom">
                <img :src="articlefrom.sourceAvatar" class="articleAvtar">
                <div class="newstitle2box">
                    <p class="newsinfoSource">{{articlefrom.infoSource}}</p>
                    <p class="newspushTime">{{articlefrom.pushTime | dataFilter}}</p>
                </div>
                <img v-if="isShoucang"  src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/加关注_1686728934219.png" class="shoucangImg" @click="toShoucang">
                <img v-else  src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/加关注(1)_1686729283511.png" class="shoucangImg" @click="noShoucang">
                <!-- <span>来源：{{articlefrom.infoSource}}</span>
                <span>时间：{{articlefrom.pushTime | dataFilter}}</span> -->
            </div>

            <div class="newsbox2">

                <div class="newsitem"  v-if="articlefrom">



                </div>


            </div>
            
        </div>

        <Foot />
        
        <div class="toGrzx" @click="toGrzx">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/个人中心(1)(1)_1686566476728.png" class="toGrzxImg">
        </div>

    </div>
</template>



<script>
import Cookies from 'js-cookie';
import Foot from "@/components/foot.vue"
import {getArticleDetail,getListColumn,getMovieArticleById,addOrUpdateUserInterest,getUserInterestResult} from "@/api/index"
import { Loading ,Message } from 'element-ui';
import store from '@/store'

export default {
    components:{
        Foot
    },
    data(){
        return {
            navtop : null,
            articlefrom:null,
            headerflag : false,
            hiddenflag : false,
            columnData:null,
            load : null,
            isShoucang:1,
            wjsToken: null,
            wjsUserInfo: null,
        }
    },
    computed: {
        columnList() {
            return this.$store.getters.columnList
        },
    },
    methods:{
        toShoucang(){
            if(!this.wjsToken){
                this.showErr("请登录")
                this.toLink('dl')
                return
            }
            addOrUpdateUserInterest({
                "interestObjId": this.$route.params.articleId,
                "interestType": 0
            }).then(res => {
                if(res.code == 200){
                    this.isShoucang = 0;
                    this.showSuccess(res.message)
                }else{
                    this.showErr(res.message)
                }
                
            })
            
        },
        noShoucang(){
            if(!this.wjsToken){
                this.showErr("请登录")
                this.toLink('dl')
                return
            }
            addOrUpdateUserInterest({
                "interestObjId": this.$route.params.articleId,
                "interestType": 0
            }).then(res => {
                if(res.code == 200){
                    this.isShoucang = 1;
                    this.showSuccess(res.message)
                }else{
                    this.showErr(res.message)
                }
                
            })

        },
        toGrzx(){
            if(!Cookies.get('wjsToken')){
                this.$router.push({name:'dl'});
            }else{
                this.$router.push({name:'grzx'});
            }
        },
        toLink(name){
            //if(name == "yszb"){
                //window.location.href="http://www.zygart.cn"; 
            //}else{
                this.reset()
                this.$router.push({name:name});
            //}
        },
        toyszb(){
            this.reset()
            this.$router.push({name:'yszb'});
        },
        tojrpf(){
            this.reset()
            this.$router.push({name:'jrpf'});
        },
        tohzjg(){
            this.reset()
            this.$router.push({name:'hzjg'});
        },
        tobsxw(){
            this.reset()
            this.$router.push({name:'bsxw'});
        },
        tozcfg(){
            this.reset()
            this.$router.push({name:'zcfg'});
        },
        toindex(){
            this.reset()
            this.$router.push({name:'index'});
        },
        toaboutus(){
            this.reset()
            this.$router.push({name:'gywm'});
        },
        tonews(){
            this.reset()
            this.$router.push({name:'hydt'});
        },
        tonews2(){
            this.reset()
            document.body.scrollTop = document.documentElement.scrollTop = 0
            this.$router.push({name:'news'});
        },
        togsdt(){
            this.reset()
            this.$router.push({name:'gsdt'});
        },
        toyszx(){
            this.reset()
            this.$router.push({name:'yszx'});
        },
        tohyfx(){
            this.reset()
            this.$router.push({name:'hyfx'});
        },
        tozcjd(){
            this.reset()
            this.$router.push({name:'zcjd'});
        },
        tomovielist(){
            this.reset()
            this.$router.push({name:'xmpl'});
        },
        tongyhd(){
            this.reset()
            this.$router.push({name:'gyhd'});
        },
        touserguide(){
            this.reset()
            this.$router.push({name:'userGuide'});
        },
        tocu(){
            this.reset()
            this.$router.push({name:'connectus'});
        },
        headericon(){
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            if(this.headerflag){
                headerhidden.style.top = "-100%"
                headericon.classList.remove("headericon2")
                headericon.classList.add("headericon")
                this.headerflag = false
            }else{
                headerhidden.style.top = "1.06rem"
                headericon.classList.remove("headericon")
                headericon.classList.add("headericon2")
                this.headerflag = true
            }
        },
        headjt(){
            var headjt = document.getElementById("headjt")
            var hiddenbox = document.getElementById("hiddenbox")
            if(this.hiddenflag){
                hiddenbox.style.height = "0"
                headjt.style.transform = "rotateZ(0deg)"
                this.hiddenflag = false
            }else{
                hiddenbox.style.height = "4rem"
                headjt.style.transform = "rotateZ(-180deg)"
                this.hiddenflag = true
            }
        },
        reset(){
            this.headerflag = false
            this.hiddenflag = false
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            //var headjt = document.getElementById("headjt")
            //var hiddenbox = document.getElementById("hiddenbox")
            headerhidden.style.top = "-100%"
            headericon.classList.remove("headericon2")
            headericon.classList.add("headericon")
            //hiddenbox.style.height = "0"
            //headjt.style.transform = "rotateZ(0deg)"
        },
        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        showErr(txt){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: txt || '网络错误',
                spinner: 'el-icon-circle-close',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.load && this.load.close(); 
            },2000)
        },
        showSuccess(txt){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: txt || '成功',
                spinner: 'el-icon-circle-check',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.load && this.load.close(); 
            },2000)
        },
    },
    mounted(){
            this.wjsToken =  Cookies.get('wjsToken') || null;
            this.wjsUserInfo =  Cookies.get('wjsUserInfo') || null;

            console.log(this.wjsToken)
            console.log(this.wjsUserInfo)

            this.showLoading()
            document.body.scrollTop = document.documentElement.scrollTop = 0


            if(this.columnList){
                console.log("store" + this.columnList)
                this.columnData = this.columnList;
                this.load && this.load.close();
            }else{
                getListColumn().then(res => {
                    //console.log(res)
                    this.columnData = res.result;

                    store.dispatch('SetColumnList', res.result)

                })
            }


            getMovieArticleById({
                "id": this.$route.params.articleId,
            }).then(res => {
                if(res.code == 200){
                    this.articlefrom = res.result;

                }


                if(this.wjsToken){
                    getUserInterestResult({
                        "data": {
                            "interestObjId": this.$route.params.articleId,
                            "interestType": 0
                        }
                    }).then(res8 => {
                        if(res8.code == 200){
                            this.isShoucang = res8.result.interestStatus;
                        }

                    })
                }
                


                    this.$nextTick(res => {

                        var newsitem = document.getElementsByClassName("newsitem")[0]
                        newsitem.innerHTML = this.articlefrom.content
                        var imgs = newsitem.getElementsByTagName("img")
                        var ps = newsitem.getElementsByTagName("p")
                        var divs = newsitem.getElementsByTagName("div")


                        for(var i = 0; i < imgs.length;i++){
                            imgs[i].style.margin = "0 auto"
                            imgs[i].style.maxWidth = "7rem"
                            imgs[i].style.height = "auto" 
                            imgs[i].style.objectFit = "contain"
                        }
                        for(var j = 0; j < ps.length;j++){
                            ps[j].style.marginBottom = ".3rem"
                            ps[j].style.fontSize = ".34rem"
                            ps[j].style.lineHeight = ".56rem"
                        }
                        for(var k = 0; k < divs.length;k++){
                            divs[k].style.marginBottom = ".3rem"
                            divs[k].style.fontSize = ".34rem"
                            divs[k].style.lineHeight = ".56rem"
                        }
                        this.load && this.load.close();

                    })



                
                
            })


    },
    filters:{
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    }
}
</script>


<style lang="scss" scoped>
.newsbox{
    min-height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    z-index: 3;
}
.newstitle{
    min-height: 0.38rem;
    font-size: 0.46rem;
    font-weight: bold;
    color: #343434;
    padding: 0 0.25rem;
    margin: 0.44rem auto 0.3rem;
    text-align: justify;
}
.newstitle2{
    margin-bottom: 0.5rem;
    padding: 0 0.25rem;
    display: flex;
    align-items: center;
    position: relative;
    .shoucangImg{
        height: .46rem;
        width: .46rem;
        position: absolute;
        top: .12rem;
        right: .25rem;
    }
    .articleAvtar{
        display: block;
        border-radius: 50%;
        overflow: hidden;
        width: .8rem;
        height: .8rem;
        object-fit: cover;
        margin-right: .2rem;
        // border: 1px solid rgba(225,208,208,.5);
    }
    .newstitle2box{
        .newsinfoSource{
            font-size: .32rem;
            white-space: nowrap;
            color: #343434;
            font-weight: bold;
        }
        .newspushTime{
            font-size: .24rem;
            white-space: nowrap;
            color: #636363;

        }
    }
}
.newsitem{
    margin-bottom: 0.44rem;
    font-size: 0.26rem;
    font-weight: 400;
    line-height: 0.38rem;
    color: #343434;
    text-align: justify;
    padding: 0 0.25rem;
    box-sizing: border-box;
}
// .newsitem p{
//     width: 7rem;
//     margin: 0 auto;
//     margin-bottom: 0.24rem;
// }
// .newsitem img{
//     max-width: 7rem;
//     object-fit: contain;
//     margin: 0 auto;
// }
</style>